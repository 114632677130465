<template>
    <div class="box">
        <div class="top">
            <ul class='left'>
                <li>
                    <span>Links</span>
                    <div class="num">{{ total }}</div>
                </li>
            </ul>
            <div class="right">
                <button @click="openPop(1)">Add new</button>
            </div>
        </div>
        <div class="con-table">
            <table>
                <thead>
                    <tr>
                        <th style="width: 20%;">Social networks</th>
                        <th style="width: 40%;">Links</th>
                        <th style="width: 20%;">Hide/Show</th>
                        <th style="width: 20%;">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in list" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'">
                        <td>{{ item.title }}</td>
                        <td>{{ item.link }}</td>
                        <el-switch v-model="item.status" class="ml-2" :active-value="1" :inactive-value="0"
                            @change="status_switch(item)"
                            style="--el-switch-on-color: var(--text-color-purple); --el-switch-off-color:var(--background-light2)" />
                        <td class="icon_box">
                            <div class="icon icon_del" @click="openPop_del(item)"></div>
                            <div class="icon icon_edit" @click="openPop(2, item)"></div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <pagination :total="total" :pageSize="pageSize" :nowPage="nowPage" @goPage="goPage" @nextPage='nextPage'
            @backPage='backPage'></pagination>


        <div class="pop-bg" v-if="bg" @click="close()">

            <div class="pop_box" v-if="pop_del" @click.stop>
                <div class="icon_close" @click="close"></div>
                <div class="pop">
                    <div class="title">
                        Delete Link
                    </div>
                    <div class="text-gray left">
                        Are you sure you want to delete the link?
                    </div>
                    <div class="inp-box">
                        <textarea v-model="comment" maxlength="1250"> </textarea>
                    </div>
                    <div class="button-box">
                        <button class="cancel" @click="close">No</button>
                        <button class="submit" @click="del">Yes</button>
                    </div>
                </div>

            </div>
            <div class="pop_box" v-if="pop_create" @click.stop>
                <div class="icon_close" @click="close"></div>
                <div class="pop">
                    <div class="title">
                        {{ popType == 1 ? 'New Link' : 'Edit Link' }}
                    </div>
                    <div class="img-box">
                        <img v-if="!photoIsChange" src="../../assets/img/addImg.png" alt="" />
                        <div class="photo-box" v-loading="linkIcon == ''" v-else>
                            <img :src="linkIcon.full_path" alt="" />

                        </div>
                        <div class="img-btn">
                            <!-- <el-upload class="linkIcon-uploader" :headers="uploadHeader" :action="uploadUrl" method="post"
							:data="uploadData" list-type="picture" name='file' :show-file-list="false"
							:on-success="photoSuccess" :on-change="photoChange"> -->
                            <div class="left-btn">
                                Change photo
                                <input type="file" ref="fileInput" id="uploads"
                                    accept="image/png, image/jpeg, image/gif, image/jpg" @change="uploadImg">
                            </div>
                            <!-- 	</el-upload> -->

                            <div class="right-btn" @click="delPhoto">Delete photo</div>
                        </div>
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" v-if="title">Title</div>
                        <input v-model="title" placeholder="Title" :style="{ 'margin-top': title ? '5px' : '20px' }" />
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" v-if="link">Link</div>
                        <textarea v-model="link" placeholder="Link" maxlength="1250"></textarea>
                    </div>
                    <div class="button-box">
                        <button class="cancel" @click="close">Cancel</button>
                        <button class="submit" @click="save">Save</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import {
    ElMessage
} from 'element-plus'
import Api from '../../utils/api.js';
import pagination from '../common/pagination.vue';
export default {
    components: {
        pagination
    },
    data() {
        return {
            pageSize: 8,
            nowPage: 1,
            total: 0,
            list: [],
            popType: 1,
            comment: '',
            bg: false,
            pop_del: false,
            pop_create: false,
            comment: '',
            id: '',
            link: '',
            title: '',
            photoIsChange: false,
            linkIcon: '',
        }
    },
    mounted() {
        let table = document.getElementsByClassName('con-table')[0];
        this.pageSize = parseInt((table.clientHeight - 50) / 50);
        this.getList();
    },
    methods: {
        status_switch(item) {
            Api.networkStatus({
                id: item.id
            }).then(res => {
                if (res.code == 200) {
                    this.getList();
                    this.close();
                } else {
                    ElMessage.error(res.message)
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })
        },
        save() {
            if (this.title == '' || this.link == '' || this.linkIcon == '') {
                ElMessage('Please complete the information.');
                return;
            }

            let match2 = /^((http|https):\/\/)?(([A-Za-z0-9]+-[A-Za-z0-9]+|[A-Za-z0-9]+)\.)+([A-Za-z]+)[/\?\:]?.*$/;
            if (!match2.test(this.link)) {
                ElMessage({
                    message: "Please enter correctly link",
                    // type: 'error',
                    duration: 5 * 1000
                })
                return
            }
            if (this.popType == 1) {
                Api.networkCreate({
                    title: this.title,
                    link: this.link,
                    icon: this.linkIcon.half_path
                }).then(res => {
                    if (res.code == 200) {
                        this.getList();
                        this.close();
                    } else {
                        ElMessage.error(res.message)
                    }
                }).catch(err => {
                    ElMessage.error(err.message);
                })
            } else {
                Api.networkEdit({
                    id: this.id,
                    title: this.title,
                    link: this.link,
                    icon: this.linkIcon.half_path
                }).then(res => {
                    if (res.code == 200) {
                        this.getList();
                        this.close();
                    } else {
                        ElMessage.error(res.message)
                    }
                }).catch(err => {
                    ElMessage.error(err.message);
                })
            }
        },
        async uploadImg(e) {
            if (e.target.files.length === 0) {
                return
            }
            if (e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/bmp' && e.target
                .files[0].type !== 'image/png') {
                this.$message.error('Image format error!');
                return false;
            }
            this.upload(e.target.files[0]);

        },
        upload(e) {
            const formData = new FormData();
            formData.append("file", e);
            Api.uploadImages(formData).then(res => {
                if (res.code == 200) {
                    this.linkIcon = res.data[0];
                    this.photoIsChange = true;
                } else {
                    ElMessage.error(res.message)
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })
        },
        delPhoto() {
            this.linkIcon = '';
            this.photoIsChange = false;
        },
        getList() {
            Api.networkList({
                limit: this.pageSize,
                page: this.nowPage
            }).then(res => {
                if (res.code == 200) {
                    this.list = res.data.data;
                    this.total = res.data.meta.pagination.total;
                } else {
                    ElMessage.error(res.message)
                }
            })
        },
        openPop_del(item) {
            this.id = item.id;
            this.bg = true;
            this.pop_del = true;
        },
        openPop(e, item) {
            this.popType = e;
            this.bg = true;
            this.pop_create = true;
            if (item) {
                this.id = item.id;
                this.title = item.title;
                this.link = item.link;
                this.linkIcon = {
                    full_path: item.full_icon,
                    half_path: item.icon
                }
                this.photoIsChange = true;
            }
        },
        close() {
            this.bg = false;
            this.pop_del = false;
            this.pop_create = false;
            this.title = '';
            this.linkIcon = '';
            this.link = '';
            this.photoIsChange = false;
            this.comment = '';
            this.id = '';
        },
        del() {
            Api.networkDelete({
                id: this.id,
                comment: this.comment
            }).then(res => {
                if (res.code == 200) {
                    this.getList();
                    this.close();
                } else {
                    ElMessage.error(res.message)
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })
        },
    }
}
</script>
<style scoped>
.box {
    width: 100%;
    height: 100%;
    position: relative;
}

.top .left li {
    width: 100%;
}

.con-table {
    height: calc(100% - 106px);
}

.pop .img-box img,
.photo-box {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 20px;
    margin: auto;
}

.pop .img-box .photo-box {
    border: 1px solid var(--img-border)
}

.pop .img-box .img-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

}

.pop .img-box .img-btn .left-btn,
.right-btn {
    border: 1px solid var(--submit);
    border-radius: 20px;
    color: var(--text-color);
    padding: 10px 0;
    width: 100px;
    cursor: pointer;
}

.pop .img-box .img-btn .left-btn {
    position: relative;
    margin-right: 10px;
}

.pop .img-box .img-btn .left-btn input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    cursor: pointer;
}

.pop .img-box .img-btn .right-btn {
    background-color: var(--submit);
}
.con-table table tr th,td{
    text-align: center;
}
</style>