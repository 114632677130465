<template>
	<div class="login">
		<el-container>
			<el-header>
				<div class="icon icon-headerLogo"></div>
			</el-header>
			<el-main>
				<div class="pop">
					<div class="title">Reset Password</div>
					<div class="text-gray">Please enter your email to reset password</div>
					<!-- <div class="inp-box">
						<input type="text" placeholder="New Password" v-model="password1" />
					</div>
					<div class="inp-box">
						<input type="text" placeholder="New Password Confirmation" v-model="password2" />
					</div> -->
					<div class="inp-box eye">
						<input :type="password_see1 ? 'text' : 'password'" placeholder="Password" v-model="password1" />
						<img class="close" v-if="password_see1" src="../../../assets/img/close-eye.png"
							@click="password_see1 = false" alt="" />
						<img class="open" v-else src="../../../assets/img/eye.png" @click="password_see1 = true"
							alt="" />

					</div>
					<div class="inp-box eye">
						<input :type="password_see2 ? 'text' : 'password'" placeholder="Password" v-model="password2" />
						<img class="close" v-if="password_see2" src="../../../assets/img/close-eye.png"
							@click="password_see2 = false" alt="" />
						<img class="open" v-else src="../../../assets/img/eye.png" @click="password_see2 = true"
							alt="" />

					</div>
					<div class="text-gray">Password must be at least 8 characters, have one capital letter, and a
						number*</div>
					<div class="btn-box">
						<button class="submit" @click="save">SAVE</button>
					</div>
				</div>
			</el-main>
		</el-container>
	</div>
</template>

<script>
import {
	ElMessage
} from 'element-plus'

import Api from '../../../utils/api.js'
import { ElLoading } from 'element-plus'
export default {
	data() {
		return {
			password1: '',
			password2: '',
			email: '',
			info: '',
			password_see1:false,
			password_see2:false,

		}
	},
	created() {
		// this.email = this.$route.query.email
		this.info = this.$route.query.info
	},
	methods: {
		save() {
			if (this.password1 == '' || this.password2 == '') {
				ElMessage.warning('Please complete your password information');
				return;
			}
			var reg = /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{8,}$/;
			if (!reg.test(this.password1) || !reg.test(this.password2)) {
				ElMessage('Wrong password');
				return;
			}
			if (this.password1 != this.password2) {
				ElMessage.warning('Passwords are inconsistent');
				return;
			}
			let loadingInstance = ElLoading.service({
				background: 'rgba(0, 0, 0, 0.2)',
				lock: true,
			});
			Api.changePassWord({
				info: this.info,
				password: this.password1,
				confirm_password: this.password2
			}).then(res => {
				if (res.code == 200) {
					this.$nextTick(() => {
						loadingInstance.close();
					});
					// localStorage.setItem('token', res.data.access_token);
					// localStorage.setItem('token_type', res.data.token_type);
					// localStorage.setItem('expires_in', res.data.expires_in);
					this.$router.push({
						name: 'login',
					})
				} else {
					this.$nextTick(() => {
						loadingInstance.close();
					});
					ElMessage.error(res.message);
				}
			})

		}
	}
}
</script>

<style scoped>
.el-header {
	height: 100px;
	width: 100%;
	background-color: var(--background-color);

}

.el-header .icon {
	height: 74px;
	width: 210px;
	margin: 13px auto;
}

.el-main {
	background-image: url(../../../assets/img/bg.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: calc(100vh - 100px);
	display: flex;
	justify-content: center;
	align-items: center;

}

.pop .btn-box .submit {
	width: 100%;
	margin-bottom: 10px;
}

.pop .text-gray {
	text-align: left;
}
</style>