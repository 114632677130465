<template>
	<div class="login">
		<el-container>
			<el-header>
				<div class="icon icon-headerLogo"></div>
			</el-header>
			<el-main>
				<div class="pop">
					<div class="title">Reset Password</div>
					<div class="text-gray">Please enter your email to reset password</div>
					<div class="inp-box">
						<input type="text" placeholder="Email" v-model="email"/>
					</div>
					<div class="btn-box">
						<button class="submit" @click="next">RESET PASSWORD</button>
					</div>
				</div>
			</el-main>
		</el-container>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'

	import Api from '../../../utils/api.js'
	export default {
		data() {
			return {
				email:''
			}
		},
		created() {

		},
		methods: {
			next(){
				if(this.email==''){
					ElMessage.warning('Please complete your email information');
					return;
				}
				Api.resetPassword({
					email:this.email
				}).then(res=>{
					if(res.code==200){
						// window.open(res.data.url);
						ElMessage.success('Email sent successfully');
					}else{
						ElMessage.error(res.message)
					}
				})
				// this.$router.push({
				// 	name: 'reset_password',
				// 	query:{
				// 		email:this.email
				// 	}
				// })
				
			}
		}
	}
</script>

<style scoped>
	.el-header{
		height:100px;
		width: 100%;
		background-color: var(--background-color);
		
	}
	.el-header .icon{
		height: 74px;
		width: 210px;
		margin:13px auto;
	}
	.el-main{
		background-image: url(../../../assets/img/bg.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		width:100%;
		height:calc(100vh - 100px);
		display:flex;
		justify-content: center;
		align-items: center;

	}
	.pop .btn-box .submit{
		width: 100%;
		margin-bottom: 10px;
	}
	.pop .text-gray{
		text-align: left;
	}
</style>