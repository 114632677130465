import service from './request.js'

const login = (data) => {
	return service({
		url: "/auth/login",
		method: "POST",
		data
	})
}
const authMe = (data) => {
	return service({
		url: "/auth/me",
		method: "POST",
		data
	})
}

const changePassWord = (data) => {
	return service({
		url: "/auth/changePassWord",
		method: "POST",
		data
	})
}
const orderInfo = (data) => {
	return service({
		url: "order/info",
		method: "POST",
		data
	})
}
const orderDelete = (data) => {
	return service({
		url: "order/delete",
		method: "POST",
		data
	})
}
const orderComplete = (data) => {
	return service({
		url: "order/complete",
		method: "POST",
		data
	})
}
const orderRankBoosting = (data) => {
	return service({
		url: "order/rank/boosting",
		method: "POST",
		data
	})
}

const orderPlacementMatches = (data) => {
	return service({
		url: "order/placement/matches",
		method: "POST",
		data
	})
}

const orderUnratedGames = (data) => {
	return service({
		url: "order/unrated/games",
		method: "POST",
		data
	})
}

const orderRankedWins = (data) => {
	return service({
		url: "order/ranked/wins",
		method: "POST",
		data
	})
}

const orderAccountLeveling = (data) => {
	return service({
		url: "order/account/leveling",
		method: "POST",
		data
	})
}

const jobApply = (data) => {
	return service({
		url: "job/apply",
		method: "POST",
		data
	})
}
const jobUntreated = (data) => {
	return service({
		url: "job/untreated",
		method: "POST",
		data
	})
}
const jobOperation = (data) => {
	return service({
		url: "job/operation",
		method: "POST",
		data
	})
}
const userList = (data) => {
	return service({
		url: "user/list",
		method: "POST",
		data
	})
}
const userCreate = (data) => {
	return service({
		url: "user/create",
		method: "POST",
		data
	})
}
const userDelete = (data) => {
	return service({
		url: "user/delete",
		method: "POST",
		data
	})
}
const userEdit = (data) => {
	return service({
		url: "user/edit",
		method: "POST",
		data
	})
}
const boosterList = (data) => {
	return service({
		url: "booster/list",
		method: "POST",
		data
	})
}
const indexCountry = (data) => {
	return service({
		url: "index/country",
		method: "POST",
		data
	})
}

const blogList = (data) => {
	return service({
		url: "blog/list",
		method: "POST",
		data
	})
}
const blogCreate = (data) => {
	return service({
		url: "blog/create",
		method: "POST",
		data
	})
}

const blogInfo = (data) => {
	return service({
		url: "blog/info",
		method: "POST",
		data
	})
}

const blogEdit = (data) => {
	return service({
		url: "blog/edit",
		method: "POST",
		data
	})
}


const blogDelete = (data) => {
	return service({
		url: "blog/delete",
		method: "POST",
		data
	})
}


const rankBoostingPrice = (data) => {
	return service({
		url: "calculator/rank/boosting/price",
		method: "POST",
		data
	})
}

const rankBoostingEdit = (data) => {
	return service({
		url: "calculator/rank/boosting/price/edit",
		method: "POST",
		data
	})
}

const placementMatchesPrice = (data) => {
	return service({
		url: "calculator/placement/matches/price",
		method: "POST",
		data
	})
}

const placementMatchesEdit = (data) => {
	return service({
		url: "calculator/placement/matches/price/edit",
		method: "POST",
		data
	})
}
const unratedGamesPrice = (data) => {
	return service({
		url: "calculator/unrated/games/price",
		method: "POST",
		data
	})
}
const unratedGamesPriceEdit = (data) => {
	return service({
		url: "calculator/unrated/games/price/edit",
		method: "POST",
		data
	})
}

const chatToken = (data) => {
	return service({
		url: "chat/token",
		method: "POST",
		data
	})
}
const chatLogout = (data) => {
	return service({
		url: "chat/logout",
		method: "POST",
		data
	})
}
const chatRecord = (data) => {
	return service({
		url: "chat/record",
		method: "POST",
		data
	})
}
const chatRead= (data) => {
	return service({
		url: "chat/read",
		method: "POST",
		data
	})
}
const chatSendPrivate = (data) => {
	return service({
		url: "chat/send/private/message",
		method: "POST",
		data
	})
}
const chatSendGroup = (data) => {
	return service({
		url: "chat/send/group/message",
		method: "POST",
		data
	})
}
const chatSupport = (data) => {
	return service({
		url: "chat/support",
		method: "POST",
		data
	})
}
const chatList = (data) => {
	return service({
		url: "chat/list",
		method: "POST",
		data
	})
}
const chatHasGroup= (data) => {
	return service({
		url: "chat/has/group",
		method: "POST",
		data
	})
}

const faqList = (data) => {
	return service({
		url: "faq/list",
		method: "POST",
		data
	})
}
const faqCreate = (data) => {
	return service({
		url: "faq/create",
		method: "POST",
		data
	})
}

const faqInfo = (data) => {
	return service({
		url: "faq/info",
		method: "POST",
		data
	})
}

const faqEdit = (data) => {
	return service({
		url: "faq/edit",
		method: "POST",
		data
	})
}


const faqDelete = (data) => {
	return service({
		url: "faq/delete",
		method: "POST",
		data
	})
}
const faqStatus = (data) => {
	return service({
		url: "faq/status",
		method: "POST",
		data
	})
}

const privacyPolicyList = (data) => {
	return service({
		url: "privacy/policy/list",
		method: "POST",
		data
	})
}
const privacyPolicyCreate = (data) => {
	return service({
		url: "privacy/policy/create",
		method: "POST",
		data
	})
}

const privacyPolicyInfo = (data) => {
	return service({
		url: "privacy/policy/info",
		method: "POST",
		data
	})
}

const privacyPolicyEdit = (data) => {
	return service({
		url: "privacy/policy/edit",
		method: "POST",
		data
	})
}


const privacyPolicyDelete = (data) => {
	return service({
		url: "privacy/policy/delete",
		method: "POST",
		data
	})
}



const termsConditionsList = (data) => {
	return service({
		url: "terms/conditions/list",
		method: "POST",
		data
	})
}
const termsConditionsCreate = (data) => {
	return service({
		url: "terms/conditions/create",
		method: "POST",
		data
	})
}

const termsConditionsInfo = (data) => {
	return service({
		url: "terms/conditions/info",
		method: "POST",
		data
	})
}

const termsConditionsEdit = (data) => {
	return service({
		url: "terms/conditions/edit",
		method: "POST",
		data
	})
}


const termsConditionsDelete = (data) => {
	return service({
		url: "terms/conditions/delete",
		method: "POST",
		data
	})
}

const boostFineList = (data) => {
	return service({
		url: "boost/fine/list",
		method: "POST",
		data
	})
}
const boostFineCreate = (data) => {
	return service({
		url: "boost/fine/create",
		method: "POST",
		data
	})
}


const boostFineEdit = (data) => {
	return service({
		url: "boost/fine/edit",
		method: "POST",
		data
	})
}


const boostFineDelete = (data) => {
	return service({
		url: "boost/fine/delete",
		method: "POST",
		data
	})
}

const boostRuleList = (data) => {
	return service({
		url: "boost/rule/list",
		method: "POST",
		data
	})
}
const boostRuleCreate = (data) => {
	return service({
		url: "boost/rule/create",
		method: "POST",
		data
	})
}


const boostRuleEdit = (data) => {
	return service({
		url: "boost/rule/edit",
		method: "POST",
		data
	})
}


const boostRuleDelete = (data) => {
	return service({
		url: "boost/rule/delete",
		method: "POST",
		data
	})
}

const administratorList = (data) => {
	return service({
		url: "administrator/list",
		method: "POST",
		data
	})
}
const administratorCreate = (data) => {
	return service({
		url: "administrator/create",
		method: "POST",
		data
	})
}


const administratorEdit = (data) => {
	return service({
		url: "administrator/edit",
		method: "POST",
		data
	})
}


const administratorDelete = (data) => {
	return service({
		url: "administrator/delete",
		method: "POST",
		data
	})
}
const uploadImages = (data) => {
	return service({
		url: "upload/images",
		method: "POST",
		data
	})
}
const supportList = (data) => {
	return service({
		url: "support/list",
		method: "POST",
		data
	})
}

const supportStatus = (data) => {
	return service({
		url: "support/status",
		method: "POST",
		data
	})
}


const promoCodeList = (data) => {
	return service({
		url: "promo/code/list",
		method: "POST",
		data
	})
}


const promoCodeCreate = (data) => {
	return service({
		url: "promo/code/create",
		method: "POST",
		data
	})
}

const promoCodeEdit = (data) => {
	return service({
		url: "promo/code/edit",
		method: "POST",
		data
	})
}
const promoCodeDelete = (data) => {
	return service({
		url: "promo/code/delete",
		method: "POST",
		data
	})
}
const indexOnline = (data) => {
	return service({
		url: "index/online",
		method: "POST",
		data
	})
}
const orderBooster = (data) => {
	return service({
		url: "order/booster",
		method: "POST",
		data
	})
}
const orderBoosterChoose = (data) => {
	return service({
		url: "order/booster/choose",
		method: "POST",
		data
	})
}
const announcementCreate = (data) => {
	return service({
		url: "announcement/create",
		method: "POST",
		data
	})
}
const announcementInfo = (data) => {
	return service({
		url: "announcement/info",
		method: "POST",
		data
	})
}
const announcementList = (data) => {
	return service({
		url: "announcement/list",
		method: "POST",
		data
	})
}
const announcementEdit = (data) => {
	return service({
		url: "announcement/edit",
		method: "POST",
		data
	})
}
const announcementDelete = (data) => {
	return service({
		url: "announcement/delete",
		method: "POST",
		data
	})
}
const calculatorOptionalFeature = (data) => {
	return service({
		url: "calculator/optional/feature",
		method: "POST",
		data
	})
}
const calculatorOptionalFeatureEdit = (data) => {
	return service({
		url: "calculator/optional/feature/edit",
		method: "POST",
		data
	})
}
const calculatorOptionalFeatureAdd = (data) => {
	return service({
		url: "calculator/optional/feature/add",
		method: "POST",
		data
	})
}
const queueSettingList = (data) => {
	return service({
		url: "queue/setting/list",
		method: "POST",
		data
	})
}
const queueSettingEdit = (data) => {
	return service({
		url: "queue/setting/edit",
		method: "POST",
		data
	})
}
const accessSettingList = (data) => {
	return service({
		url: "access/setting/list",
		method: "POST",
		data
	})
}
const accessSettingEdit = (data) => {
	return service({
		url: "access/setting/edit",
		method: "POST",
		data
	})
}
const indexMenu = (data) => {
	return service({
		url: "index/menu",
		method: "POST",
		data
	})
}
const statisticOrder = (data) => {
	return service({
		url: "statistic/order",
		method: "POST",
		data
	})
}
const statisticOrderReport = (data) => {
	return service({
		url: "statistic/order/report",
		method: "POST",
		data
	})
}
const statisticInfo = (data) => {
	return service({
		url: "statistic/info",
		method: "POST",
		data
	})
}
const boosterInfo = (data) => {
	return service({
		url: "booster/info",
		method: "POST",
		data
	})
}
const boosterReviews = (data) => {
	return service({
		url: "booster/reviews",
		method: "POST",
		data
	})
}
const boosterSetting = (data) => {
	return service({
		url: "booster/setting",
		method: "POST",
		data
	})
}
const settingEdit = (data) => {
	return service({
		url: "booster/setting/edit",
		method: "POST",
		data
	})
}
const boosterWithdraw = (data) => {
	return service({
		url: "booster/withdraw",
		method: "POST",
		data
	})
}
const orderAll = (data) => {
	return service({
		url: "order/all",
		method: "POST",
		data
	})
}
const boosterBan = (data) => {
	return service({
		url: "booster/ban",
		method: "POST",
		data
	})
}
const boosterEdit = (data) => {
	return service({
		url: "booster/edit",
		method: "POST",
		data
	})
}
const boosterDelete = (data) => {
	return service({
		url: "booster/delete",
		method: "POST",
		data
	})
}
const personalInfo = (data) => {
	return service({
		url: "booster/personal/info",
		method: "POST",
		data
	})
}
const calculatorAcc = (data) => {
	return service({
		url: "calculator/acc",
		method: "POST",
		data
	})
}
const calculatorAccEdit = (data) => {
	return service({
		url: "calculator/acc/edit",
		method: "POST",
		data
	})
}
const networkCreate = (data) => {
	return service({
		url: "social/network/create",
		method: "POST",
		data
	})
}
const networkList = (data) => {
	return service({
		url: "social/network/list",
		method: "POST",
		data
	})
}
const networkStatus = (data) => {
	return service({
		url: "social/network/status",
		method: "POST",
		data
	})
}
const networkEdit = (data) => {
	return service({
		url: "social/network/edit",
		method: "POST",
		data
	})
}
const networkDelete = (data) => {
	return service({
		url: "social/network/delete",
		method: "POST",
		data
	})
}
const contactList = (data) => {
	return service({
		url: "contact/list",
		method: "POST",
		data
	})
}
const contactOfficial = (data) => {
	return service({
		url: "contact/official",
		method: "POST",
		data
	})
}
const contactOfficialEdit = (data) => {
	return service({
		url: "contact/official/edit",
		method: "POST",
		data
	})
}
//add,9-12
const resetOrder = (data) => {
	return service({
		url: "order/active",
		method: "POST",
		data
	})
}
const deleteUserList = (data) => {
	return service({
		url: "delete/user/list",
		method: "POST",
		data
	})
}
const deleteUserCount = (data) => {
	return service({
		url: "delete/user/count",
		method: "POST",
		data
	})
}

const deleteUserRestore = (data) => {
	return service({
		url: "delete/user/restore",
		method: "POST",
		data
	})
}
const deleteUserReport = (data) => {
	return service({
		url: "delete/user/report",
		method: "POST",
		data
	})
}
const orderCount = (data) => {
	return service({
		url: "order/count",
		method: "POST",
		data
	})
}

const indexRegion = (data) => {
	return service({
		url: "index/region",
		method: "POST",
		data
	})
}
const indexDivision = (data) => {
	return service({
		url: "index/division",
		method: "POST",
		data
	})
}
const boosterCreate = (data) => {
	return service({
		url: "booster/create",
		method: "POST",
		data
	})
}
const resetPassword = (data) => {
	return service({
		url: "auth/reset/password",
		method: "POST",
		data
	})
}






export default {
	resetPassword,
	boosterCreate,
	indexDivision,
	indexRegion,
	orderCount,
	jobUntreated,
	deleteUserCount,
	deleteUserReport,
	deleteUserRestore,
	deleteUserList,
	unratedGamesPriceEdit,
	unratedGamesPrice,
	contactOfficialEdit,
	contactOfficial,
	contactList,
	networkDelete,
	networkEdit,
	networkStatus,
	networkList,
	networkCreate,
	calculatorAcc,
	calculatorAccEdit,
	authMe,
	personalInfo,
	boosterDelete,
	boosterEdit,
	boosterBan,
	orderAll,
	boosterWithdraw,
	settingEdit,
	boosterSetting,
	boosterReviews,
	boosterInfo,
	statisticInfo,
	statisticOrderReport,
	statisticOrder,
	indexMenu,
	accessSettingEdit,
	accessSettingList,
	queueSettingEdit,
	queueSettingList,
	chatRead,
	chatHasGroup,
	chatList,
	calculatorOptionalFeatureAdd,
	calculatorOptionalFeatureEdit,
	calculatorOptionalFeature,
	announcementDelete,
	announcementEdit,
	announcementList,
	announcementInfo,
	announcementCreate,
	orderBoosterChoose,
	orderBooster,
	indexOnline,
	promoCodeDelete,
	promoCodeEdit,
	promoCodeCreate,
	promoCodeList,
	supportStatus,
	supportList,
	uploadImages,
	administratorDelete,
	administratorEdit,
	administratorCreate,
	administratorList,
	boostRuleDelete,
	boostRuleEdit,
	boostRuleCreate,
	boostRuleList,
	boostFineDelete,
	boostFineEdit,
	boostFineCreate,
	boostFineList,
	termsConditionsDelete,
	termsConditionsEdit,
	termsConditionsInfo,
	termsConditionsCreate,
	termsConditionsList,
	privacyPolicyDelete,
	privacyPolicyEdit,
	privacyPolicyInfo,
	privacyPolicyCreate,
	privacyPolicyList,
	faqStatus,
	faqDelete,
	faqEdit,
	faqInfo,
	faqCreate,
	faqList,
	chatSupport,
	chatSendGroup,
	chatSendPrivate,
	chatRecord,
	chatLogout,
	chatToken,
	placementMatchesEdit,
	placementMatchesPrice,
	rankBoostingEdit,
	rankBoostingPrice,
	blogDelete,
	blogEdit,
	blogInfo,
	blogCreate,
	blogList,
	indexCountry,
	boosterList,
	userDelete,
	userEdit,
	userCreate,
	userList,
	login,
	changePassWord,
	orderDelete,
	orderComplete,
	orderInfo,
	orderRankBoosting,
	orderPlacementMatches,
	orderUnratedGames,
	orderRankedWins,
	orderAccountLeveling,
	jobApply,
	jobOperation,
	resetOrder//add
}