<template>
    <div class="balance">
        <div class="top">
            <div class="flex">
                <img @click="back" src="../../../assets/img/back_submit.png">
                <ul class='left'>
                    <li :class="tab == 1 ? 'active' : ''" @click="tab = 1">
                        <span>Balance</span>
                    </li>
                    <div class="line"></div>
                    <li :class="tab == 2 ? 'active' : ''" @click="tab = 1">
                        <span>Fine</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="user">
            <img :src="setting.avatar">
            <div class="name">
                {{ setting.username }}
                <span class="online" v-if="setting.status == 1"></span>
            </div>
            <div class="price">
                <span>Available balance</span>
                <div>{{ setting.available_balance }} $</div>
            </div>
        </div>
        <div class="con-table">
            <table>
                <thead>
                    <tr>
                        <th style="width: 20%;">ID</th>
                        <th style="width: 15%;">Card</th>
                        <th style="width: 25%;">Summ</th>
                        <th style="width: 20%;">Date/Time</th>
                        <th style="width: 20%;">Type</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in balanceList" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'">
                        <td>{{ item.id }}</td>
                        <td>{{ item.card }} </td>
                        <td>{{ item.money }}</td>
                        <td>{{ getDate(item.created_at) }}</td>
                        <td style="color:var(--text-color-blue)" v-if="item.status==0">In processing</td>
                        <td style="color:var(--text-color-green)" v-if="item.status==1">Already arrived</td>
                        <td style="color:var(--submit)" v-if="item.status==2">Withdraw failed</td>
                        

                    </tr>
                </tbody>
            </table>

            <pagination :total="total" :pageSize="pageSize" :nowPage="nowPage" @goPage="goPage" @nextPage='nextPage'
                @backPage='backPage'></pagination>
        </div>
    </div>
</template>
<script>
import {
    ElMessage, ElLoading
} from 'element-plus'
import Api from '../../../utils/api.js';
import pagination from '../../common/pagination.vue';
export default {
    components: {
        pagination
    },
    data() {
        return {
            booster_id: '',
            nowPage: 1,
            total: 0,
            pageSize: 0,
            tab: 1,
            balanceList: [],
            setting: ''
        }
    },
    mounted() {
        let table = document.getElementsByClassName('balance')[0];
        this.pageSize = parseInt((table.clientHeight - 200) / 50);
        this.booster_id = this.$route.query.booster_id;
        this.getBalanceList();
        this.getSetting();

    },
    methods: {
        getSetting() {
            Api.boosterSetting({
                booster_id: this.booster_id,
            }).then(res => {
                if (res.code == 200) {
                    this.setting = res.data;

                } else {
                    ElMessage.error(res.message)

                }
            })
        },
        getDate(e) {
            const date = new Date(e ? e * 1000 : '');
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hour = date.getHours();
            let minute = date.getMinutes();
            month = month > 9 ? month : '0' + month;
            day = day > 9 ? day : '0' + day;
            hour = hour > 9 ? hour : '0' + hour;
            minute = minute > 9 ? minute : '0' + minute;
            return `${day}.${month}.${year}/${hour}:${minute}`
        },
        getBalanceList() {
            let loadingInstance = ElLoading.service({
                background: 'rgba(0, 0, 0, 0.2)',
                lock: true,
            });
            Api.boosterWithdraw({
                booster_id: this.booster_id,
                limit: this.pageSize,
                page: this.nowPage,
            }).then(res => {
                if (res.code == 200) {
                    this.balanceList = res.data.data;
                    this.total = res.data.meta.pagination.total;
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                } else {
                    ElMessage.error(res.message)
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                }
            })
        },
        back() {
            this.$router.go(-1);
        },
        goPage(e) {
            this.nowPage = e
            if (this.tab == 1) {
                this.getBalanceList();
            }
            console.log('nowPage:', this.nowPage)
        },
        nextPage(e) {
            this.nowPage = e
            if (this.tab == 1) {
                this.getBalanceList();
            }
            console.log('nowPage:', this.nowPage)
        },
        backPage(e) {
            this.nowPage = e
            if (this.tab == 1) {
                this.getBalanceList();
            }
            console.log('nowPage:', this.nowPage)
        },
    }
}
</script>
<style scoped>
.balance {
    width: 100%;
    height: calc(100%);
    position: relative;
}

.balance .top .flex {
    display: flex;
}

.balance .top .flex img {
    width: 30px;
    height: 30px;
    margin-right: 30px;
    cursor: pointer;
}

.balance .top .left li {
    padding: 10px 20px;
    width:80px;
    cursor: pointer;
}

.balance .user {
    display: flex;
    align-items: flex-end;
    margin: 20px 0;
}

.balance .user img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 20px;
    border: 1px solid var(--img-border);
}

.balance .user .name {
    color: var(--text-color);
    font-size: 20px;
    font-weight: bolder;
    position: relative;
}

.balance .user .name .online {
    position: absolute;
    right: -6px;
    top: -5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--text-color-green);
}

.balance .user .price {
    border-radius: 48px;
    padding: 15px 20px;
    width: 200px;
    background-color: var(--background-light3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 40px;
}

.balance .user .price span {
    color: var(--text-color);
    font-size: 14px;
}

.balance .user .price div {
    color: var(--text-color-purple);
    font-size: 16px;
    font-weight: bolder;
}

.balance .con-table table tr td {
    white-space: normal;
    color: #fff;
}

.balance .con-table table tr th {
    color: var(--text-color-light);
}
</style>