import axios from "axios";
import {
	baseUrl
} from './config.js'
import router from '../router/index.js';
import {
	ElMessage
} from 'element-plus'

const service = axios.create({
	baseURL: baseUrl,
	timeout: 8000,
	headers: {
		// "Content-type": "application/json;charset=utf-8",
		// 'Content-Type': 'application/x-www-form-urlencoded'
		// 'Accept':"application/json"
	},
})
service.interceptors.request.use((config) => {
	config.headers = config.headers || {}
	if (localStorage.getItem("token")) {
		config.headers.Authorization = localStorage.getItem("token_type") + ' ' + localStorage.getItem("token") || ""
	}
	if (!localStorage.getItem('lang')) {
		localStorage.setItem('lang', 'En')
	}
	config.headers.lang = localStorage.getItem('lang').toLowerCase();
	return config
}, error => {
	return Promise.reject(error)
})

service.interceptors.response.use((res) => {
	const code = res.data.code;
	// if (code == 200 ) {
	// 		return Promise.resolve(res.data)

	// }else {
	//   ElMessage({
	//     message: res.data.message,
	//     type: 'error',
	//     duration: 5 * 1000
	//   })
	//   return Promise.resolve(res.data)
	// }
	return res.data
}, (err) => {
	if (err.response) {
		if (err.response.status == 403 || err.response.status == 401) {
			localStorage.setItem('token', '');
			localStorage.setItem('token_type', '');
			localStorage.setItem('expires_in', '');
			router.push('/login');
		}else{
			return err
		}
	}
	
})

export default service